
.projects {
    background-color: rgb(255, 252, 244);
    display: flex;
    flex-direction: column;
    align-items: center;
    /* --thorange: rgb(221, 169, 124);
    --twhite: rgb(255, 252, 244);
    --tlack: rgb(45, 50, 55); */
}

.projects h1 {
    color: var(--tlack);
}

.projects-container {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 2rem;
}

.project-card {
    /* width: 30rem; */
    flex-basis: 40%;
    min-height: 340px;
    background-color: var(--tlack);
    /* border-radius: 1rem; */
    /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
    box-shadow: rgba(45, 50, 55, .2) 0 -25px 18px -14px inset,rgba(45, 50, 55, .15) 0 1px 2px,rgba(45, 50, 55, .15) 0 2px 4px,rgba(45, 50, 55, .15) 0 4px 8px,rgba(45, 50, 55, .15) 0 8px 16px,rgba(45, 50, 55, .15) 0 16px 32px;
    color: var(--twhite);
    margin: 1rem;
    padding: 1rem;
    z-index: 1;
}

.project-front, .project-back {
    display: block;
    transition: all 0.5s linear;
}

.project-front-content, .project-back-content {
    display: flex;
    flex-direction: column;
}

.hidden {
    display: none;
}

.visually-hidden {
    opacity: 0;
}

.project-card h2 {
    margin: 0;
}

.more-info {
    /* float: right; */
    font-size: 2rem;
    cursor: pointer;
    transition: all 250ms linear;
    align-self: flex-end;
}

.close {
    /* float: right; */
    font-size: xx-large;
    font-weight: 700;
    cursor: pointer;
    transition: all 250ms linear;
    align-self: flex-end;
    height: 10%;
}

.more-info:hover, .close:hover {
    /* color: rgb(255, 252, 244); */
    transform: scale(1.25);
}

.info-btn {
    display: inline-flex;
    align-items: center;
    --icon-opacity: 0;
    --icon-translate: -20px;
}

.info-btn:hover {
    --icon-opacity: 1;
    --icon-translate: 0px;
}

.info-icon {
    min-width: 24px;
    width: 24px;
    height: 24px;
    margin-left: 5px;
    transform: translateX(var(--icon-translate));
    opacity: var(--icon-opacity);
    transition: all .3s;
}

.project-card h2, .project-card h3 {
    text-align: center;
}

.project-stack {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.project-stack svg{
    height: 35px;
    width: 35px;
    margin: .25rem;
}

.project-tooltip {
    position: relative;
    /* border-bottom:1px dashed #000; */
}

.project-tooltip::before {
    content: attr(data-text);
    position: absolute;
    /* top: 50%; */
    /* transform: translateY(-50%); */
    left: 50%;
    margin-left: -50px;
    bottom: 100%;
    width:70px;
    padding:10px;
    border-radius:10px;
    background:var(--thorange);
    color: var(--tlack);
    text-align:center;
    /* display:none; */
    z-index: 1;
    transition: all 0.3s ease;
    opacity: 0;
}

.project-tooltip:hover::before {
    /* display:block; */
    opacity: 1;
}

.project-links {
    display: flex;
    justify-content: center;
}

/* .project-links a {
    text-decoration: none;
} */

/* .project-links button {
  background-color: rgb(255, 252, 244);
  border-radius: 5px;
  box-shadow: rgba(45, 50, 55, .2) 0 -25px 18px -14px inset,rgba(45, 50, 55, .15) 0 1px 2px,rgba(45, 50, 55, .15) 0 2px 4px,rgba(45, 50, 55, .15) 0 4px 8px,rgba(45, 50, 55, .15) 0 8px 16px,rgba(45, 50, 55, .15) 0 16px 32px;
  color: rgb(45, 50, 55);
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular,-apple-system,system-ui,Roboto,sans-serif;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin: 1rem 1rem 0 1rem;
  font-weight: 600;
} */

.card-btn {
    background-color: transparent;
    border: 3px solid var(--twhite);
    color: var(--twhite);
    border-radius: .5rem;
    /* font-size: 1.2rem; */
    font-weight: 600;
    /* margin-top: 2rem; */
    margin: 1rem 1rem 0 1rem;
    overflow: hidden;
    padding: .7rem .75rem;
    position: relative;
    text-decoration: none;
    transition: all .3s ease;
    /* width: 90%; */
    z-index: 10;
}

.card-btn::before {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: scaleY(0);
    transition: .3s all ease;
    width: 100%;
    z-index: -1;
    background-color: var(--thorange);
}

.card-btn:hover {
    color: var(--tlack);
}

.card-btn:hover::before {
    transform: scaleY(1);
}

.project-links button:hover {
  box-shadow: rgba(45, 50, 55,.35) 0 -25px 18px -14px inset,rgba(45, 50, 55,.25) 0 1px 2px,rgba(45, 50, 55,.25) 0 2px 4px,rgba(45, 50, 55,.25) 0 4px 8px,rgba(45, 50, 55,.25) 0 8px 16px,rgba(45, 50, 55,.25) 0 16px 32px;
  transform: scale(1.05);
}

@media screen and (max-width: 768px) {
    .projects-container {
        flex-direction: column;
    }

    .more-info, .close {
        font-size: 2.5rem;
    }
}