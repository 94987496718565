body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: Georgia, 'Times New Roman', Times, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(255, 252, 244);
  --thorange: rgb(221, 169, 124);
  --twhite: rgb(255, 252, 244);
  --tlack: rgb(45, 50, 55);
}

html {
  scroll-behavior: smooth;
}

.fixed-pos {
  height: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.svgcolor-light {
  top: 0;
}

@supports (-webkit-touch-callout: none) {
  .svgcolor-light.down {
    transform: translateY(0px);
  }
  
  .svgcolor-light.up {
    transform: translateY(1px);
  }
}

.content {
  margin-top: 80px;
}