
.experience {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(221, 169, 124);
    margin: 2rem auto;
    /* padding-bottom: 2rem; */
}

.experience h1 {
    color: rgb(45, 50, 55);
}

.jobs-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 2rem;
    flex-wrap: wrap;
    gap: 1rem;
}

.job-card {
    background-color: transparent;
    width: 25%;
    min-width: 20rem;
    max-width: 23rem;
    height: 20rem;
    perspective: 1000px;
    margin: 1rem auto;
}

.job-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    /* border-radius: 1rem; */
}

.job-card.is-flipped .job-card-inner {
    transform: rotateY(180deg);
}

.job-card-front, .job-card-back {
    background-color: rgb(45, 50, 55);
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
    /* border-radius: 1rem; */
    box-shadow: rgba(45, 50, 55,.35) 0 -25px 18px -14px inset,rgba(45, 50, 55,.25) 0 1px 2px,rgba(45, 50, 55,.25) 0 2px 4px,rgba(45, 50, 55,.25) 0 4px 8px,rgba(45, 50, 55,.25) 0 8px 16px,rgba(45, 50, 55,.25) 0 16px 32px;
}

.job-card-front {
    color: rgb(255, 252, 244);
  }

.job-card-back {
    color: rgb(255, 252, 244);
    transform: rotateY(180deg);
}

.job-more-info {
    /* float: right; */
    font-size: x-large;
    cursor: pointer;
    transition: all 250ms linear;
    position: absolute;
    top: 3%;
    right: 1%;
}

.job-close {
    font-weight: 400;
    font-size: xx-large;
    top: 1%;
    right: 3%;
    cursor: pointer;
    transition: all 250ms linear;
    position: absolute;
}

.job-more-info:hover, .job-close:hover {
    /* color: rgb(255, 252, 244); */
    transform: scale(1.25);
}

.job-card-header {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 1rem;
    justify-content: center;
}
/*svg width 70% h 15mm */

.job-card-header img {
    width: 3rem;
    height: auto;
}

.job-card-header h2 {
    text-align: center;
    /* margin-left: 1rem; */
}

.job-card a {
    color: rgb(255, 252, 244);
}

@media screen and (max-width: 768px) {
    .jobs-container {
        flex-direction: column;
        align-items: center;
    }

    .job-card{
        width: 80%;
        margin: 1rem 0;
    }
}