
.footer {
    background-color: rgb(45, 50, 55);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 0 2px 2px rgba(9, 9, 9, 0.23);
    color: rgb(255, 252, 244);
}

.footer-links {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.footer svg {
    margin: 0 .75rem;
    height: 40px;
    width: 40px;
}

.ft {
    display: flex;
    gap: 2vw;
}

.ctForm {
    display: flex;
    flex-direction: column;
    /* margin: auto 1vw; */
}

.ctForm input, textarea {
    background-color: rgb(255, 252, 244);
    border: none;
    padding: 1vh;
    margin-top: .5vh;
}

.ctForm label:not(:first-child) {
    margin-top: 1vh;
}

.ctForm button {
    background-color: transparent;
    border: 3px solid var(--twhite);
    color: var(--twhite);
    border-radius: .5rem;
    font-weight: 600;
    margin: 1rem 1rem 0 1rem;
    overflow: hidden;
    padding: .7rem .75rem;
    position: relative;
    transition: all .3s ease;
    z-index: 10;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.ctForm button::before {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: scaleY(0);
    transition: .3s all ease;
    width: 100%;
    z-index: -1;
    background-color: var(--thorange);
}

.ctForm button:hover {
    color: var(--tlack);
    cursor: pointer;
}

.ctForm button:hover::before {
    transform: scaleY(1);
}

.tooltip {
    position: relative;
}

.tooltip::before {
    content: attr(data-text);
    position: absolute;
    /* top: 50%; */
    /* transform: translateY(-50%); */
    left: 50%;
    margin-left: -45px;
    bottom: 250%;
    width:70px;
    padding:10px;
    border-radius:10px;
    color:rgb(45, 50, 55);
    background: rgb(255, 252, 244);
    text-align:center;
    display:none;
    z-index: 1;
}

.tooltip:hover:before {
    display:block;
  }

  @media screen and (max-width: 768px) {

    .ft {
        flex-direction: column;
    }

    .footer-links {
        flex-direction: row;
    }
  }