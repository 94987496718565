.about {
    background-color: rgb(255, 252, 244);
    /* background-color: rgb(255, 250, 235); */
    margin: 1rem auto;
}

.about h1, .about h2, .about h3, .about p {
    color: rgb(45, 50, 55);
}

.about-sect-1 { 
    display: flex;
    justify-content: center;
    /* transform-box: view-box; */
    /* transition: 1s all ease; */
    animation: 2s ease-out forwards fadein;
    overflow-y: hidden;

    /* spinning animation styling */
    /* position: relative;
    height: 350px; */
}

@keyframes fadein {
    from {
        /* transform: translateY(50px); */
        opacity: 0;
    }
    to {
        /* transform: translateY(0); */
        opacity: 1;
    }
}

.headshot {
    margin: 2rem;
    flex-basis: 20%;
    display: flex;
    justify-content: flex-end;

    /* spinning animation styling */
    /* margin: 2rem;
    flex-basis: 40%;
    display: flex;
    justify-content: flex-end; */
}

.headshot img {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 50%;
    
    /* spinning animation styling */
    /* width: 250px;
    height: 250px;
    object-fit: cover;
    border-radius: 50%;
    transition: all 1s ease;
    position: absolute;
    left: 0;
    top: 3rem; */

    /* left: 15%;
    animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite; */
    
}

/* spinning animation styling */
/* @keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes moveLeftToRight {
    0% {
        left: -100px;
    }
    100% {
        left: 100%;
    }
} */


.blurb {
    flex-basis: 40%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
    box-sizing: border-box;
    align-items: flex-start;
}

.blurb h1 {
    margin: 0;
}

.blurb h3 {
    line-height: 1.5rem;
}

/* typewriter animation */
/* .blurb h1 {
    overflow: hidden;
    white-space: nowrap;
    border-right: .15em solid transparent;
    margin: 0 auto;
    animation: typing 2.5s steps(40, end), blink-caret .5s step-end 5;
} */

/* typewriter animation */
/* @keyframes typing {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

@keyframes blink-caret {
    from, to {
        border-color: transparent;
    }
    50% {
        border-color: orange;
    }
} */

.about-sect-2 {
    display: flex;
}

.skills-sect, .edu-sect {
    flex-basis: 50%;
}

.skills-sect {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    transform: translateX(-100%);
    animation: 1s ease-out forwards slide;
}

@keyframes slide {
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.skills-logos {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.skills-logos > * > * {
    margin: .5rem;
}

.edu-sect {
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateX(100%);
    animation: 1s ease-out forwards slide;
}

.schools-sect {
    display: flex;
    justify-content: center;
}

.school-1, .school-2 {
    flex-basis: 40%;
    padding: 0 2rem;
}

.school-1 {
    text-align: right;
    align-self: center;
}

.school-2 {
    border-left: 1px solid rgb(221, 169, 124);
}

@media screen and (max-width: 768px) {
    .about-sect-1 {
        flex-wrap: wrap;
    }

    .headshot {
        flex-basis: auto;
        margin-bottom: 1rem;
    }

    .blurb {
        /* align-items: center; */
        flex-basis: auto;
        padding: 0 2rem;
        text-align: center;
    }

    .blurb h1 {
        margin-bottom: 0;
    }
    
    .about-sect-2 {
        flex-direction: column;
        overflow-x: hidden;
    }

    .about-sect-2 > * {
        margin: .5rem 0;
    }

    .skills-line-1 > *{
        margin: .4rem;
    }

    .school-1, .school-2 {
        flex-basis: 40%;
        padding: 0 1rem;
    }

    .skills-sect {
        animation: none;
        transform: translateX(-100%);
        opacity: 0;
        transition: 1s all ease;
    }

    .edu-sect {
        animation: none;
        transform: translateX(100%);
        opacity: 0;
        transition: 1s all ease;
    }

    .skills-sect.active, .edu-sect.active {
        transform: translateX(0);
        opacity: 1;
    }
    
}