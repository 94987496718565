
.header {
    top: 0;
    position: fixed;
    z-index: 10;
    height: 65px;
    width: 100%;
    box-sizing: border-box;
    /* box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23); */
}

.menu, .change {
    cursor: pointer;
    position: absolute;
    right: 25px;
    display: none;
}

.bar1, .bar2, .bar3 {
    width: 35px;
    height: 3px;
    background-color: white;
    margin: 10px 0;
    transition: 0.4s;
    border-radius: 1px;
}

.change .bar1 {
    transform: translate(0, 13px) rotate(-45deg);
}

.change .bar2 {
    opacity: 0;
}

.change .bar3 {
    transform: translate(0, -13px) rotate(45deg);
}

.navigation {
    height: 95%;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    /* padding: 0.5rem 0rem; */
    background-color: rgb(45, 50, 55);
    color: white;
}

.logo {
    text-decoration: none;
    color: white;
    font-size: 1.3rem;
    margin-left: 1rem;
}

.navbar {
    margin-left: auto;
}

.navbar ul {
    display: flex;
    padding: 0;
}

.navbar li {
    list-style-type: none;
    margin: 0 1rem;
}

.navbar li a {
    text-decoration: none;
    display: block;
    width: 100%;
    color: white;
}

.navbar li.active a {
    color: rgb(236, 253, 2);
}

.navbar li.active svg path{
    fill: rgb(236, 253, 2);
}

.indicator-container {
    width: 100%;
    height: 5%;
    background-color: transparent;
}

.indicator-bar {
    height: 100%;
    background-color: #49a8f5;
    width: 0%;
}

@media screen and (max-width: 768px) {
    .menu, .change {
        display: block;
    }

    .logo {
        font-size: 1.8rem;
    }

    .navbar ul {
        display: none;
    }

    .navbar.expanded ul {
        display: block;
        position: absolute;
        height: 100vh;
        z-index: 1;
        padding: 0;
        flex-direction: column;
        width: 100%;
        top: 45px;
        left: 0;
        background-color: rgb(45, 50, 55);
        overflow: hidden;
    }

    .navbar li {
        text-align: center;
        margin: 0;
    }

    .navbar li a {
        color: white;
        width: 100%;
        padding: 1.5rem 0;
        font-size: x-large;
    }

    .navbar li svg {
        height: 25px;
        width: 25px;
    }

    .navbar li:hover {
        background-color: rgb(0,0,0);
    }
}